.loader {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 50px;
	vertical-align: middle;
}

.loader-bars:before,
.loader-bars:after,
.loader-bars span {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 30px;
	height: 90px;
	background-color: #00255f;
	-webkit-animation: grow 1.5s linear infinite;
	animation: grow 1.5s linear infinite;
}

.loader-bars:after {
	left: 45px;
	-webkit-animation: grow 1.5s linear -.5s infinite;
	animation: grow 1.5s linear -.5s infinite;
}

.loader-bars span {
	left: 90px;
	-webkit-animation: grow 1.5s linear -1s infinite;
	animation: grow 1.5s linear -1s infinite;
}

@keyframes grow {
	0% { -webkit-transform: scaleY(0); transform: scaleY(0); opacity: 0;}
	50% { -webkit-transform: scaleY(1); transform: scaleY(1); opacity: 1;}
	100% { -webkit-transform: scaleY(0); transform: scaleY(0); opacity: 0;}
}

  .scroller::-webkit-scrollbar {
    width: 12px;
	background-color: white
    /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/

}
.scroller::-webkit-scrollbar-track {
    background-color: #d9eaf3;
	border-top-left-radius: 4px;
    /*background: red;*/
}
.scroller::-webkit-scrollbar-thumb {
	background-color: #a2d1ec;
	border-top-left-radius: 4px;
    /*outline: 1px solid slategrey;*/
}

.scroller{
overflow: scroll;
display: inline-block;
scrollbar-color: #bee3f8 #ebf8ff;
}


/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: -21px;
  height: 15px;
  width: 15px;
background-color: #eee;
border-radius: 16%;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radiocircle {
  position: absolute;
  top: 0;
  left: -21px;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 50%;
}

.radiocircle:after {
  content: "";
  position: absolute;
  display: none;
}

.multiSelect {
  position: relative;
  cursor: pointer;
  font-weight: normal;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.multiSelect input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.multiSelect:hover input ~ .checkmark {
  background-color: #d9eaf3;
}

.multiSelect input:checked ~ .checkmark {
background-color: #009ece;
text-align: center;
}

.multiSelect input:focus ~ .checkmark {
  outline: 2px auto rgb(229, 151, 0);
  outline-offset: -2px;
  }

.multiSelect:hover input ~ .radiocircle {
  background-color: #d9eaf3;
}

.multiSelect input:checked ~ .radiocircle {
  background-color: #009ece;
}

.multiSelect input:focus ~ .radiocircle {
  outline: 2px auto rgb(229, 151, 0);
  outline-offset: -2px;
  }


.flipped {
-webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

@media only screen and (max-width: 800px) {
	.scroller::-webkit-scrollbar {
		width: 8px;
		cursor: pointer;
		background-color: white
		/*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
	
	}
	.scroller::-webkit-scrollbar-track {
		background-color: #d9eaf3;
		cursor: pointer;
		border-top-left-radius: 2px;
		/*background: red;*/
	}
	.scroller::-webkit-scrollbar-thumb {
		cursor: pointer;
		background-color: #a2d1ec;
		border-top-left-radius: 2px;
		/*outline: 1px solid slategrey;*/
	}
  }
